import React from 'react'
import Image from '~/components/atoms/Image'
import Video from '~/components/atoms/Video'
import FindCta from '~/components/molecules/FindCta';
import RentalCta from '~/components/molecules/RentalCta';
import TextCard from '~/components/molecules/TextCard';
import HelpFeatures from '~/components/molecules/HelpFeatures';
import FadeInWhenVisible from '~/components/FadeInWhenVisible'
import MultiPropertyOwnerForm from '../molecules/MultiPropertyOwnerForm';

export default function Hero({ key, index, ...props }) {
  const section = props.hero
  const imagePosition = section.isHomepage ? "right" : "center";

  return (
    <section id={section.fieldGroupName + index} className="mb-20 md:mb-36 md:h-screen md:max-h-[860px] relative text-white">
      <div className="absolute inset-0">
        {section?.video?.localFile?.publicURL ? (
          <Video source={section.video.localFile.publicURL} className="w-full h-full object-cover" autoPlay loop muted playsInline />
        ) : (
          <Image loading={'eager'} data={section.image} className="w-full h-full" imageClassName="w-full h-full" objectPosition={imagePosition} />
        )}

        <div className="absolute inset-0 bg-navy opacity-10"></div>
        <div className="absolute inset-0 bottom-auto h-40 opacity-50 bg-gradient-to-b from-navy"></div>
        <div className="absolute inset-0 opacity-75 bg-gradient-to-r from-navy"></div>
      </div>


      {(section.form === 'Find Home' || section.form === 'Get Estimate') && (
        <div className="container py-32 pb-20 md:pb-0 md:py-0 h-full relative flex flex-col justify-center">
          <div className="max-w-[30rem]">
            <TextCard {...section.textCard} />
          </div>
          {section.form && (
            <FadeInWhenVisible transition={{ delay: '.25' }} className="max-w-4xl">
              {section.form === 'Find Home' ? (
                <FindCta className="text-white" />
              ) : section.form === 'Get Estimate' ? (
                <RentalCta isHero={true} className="text-white" language={section.language ?? 'en'} />
              ) : null}
            </FadeInWhenVisible>
          )}
          {section.showHelpFeatures && (
            <HelpFeatures language={section.language ?? 'en'} />
          )}
        </div>
      )}

      {/* New container for the multi-property-owner-form */}
      {section.form === 'multi-property-owner-form' && (
        <div class="container" id="multi-property-owner-form-container" >
          <div class="grid grid-cols-12 items-center">
            <div class="col-span-12 md:col-span-7 mb-10 md:mb-12 grid md:grid-cols-5 h-full items-center">
              <div class="space-y-6 text-center col-span-5 text-center mt-20 pt-20">
                <div class="space-y-4">
                  <TextCard {...section.textCard} />
                  <div class="mt-2">
                    {section.showHelpFeatures && (
                      <HelpFeatures language={section.language ?? 'en'} />
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div class="col-span-12 md:col-span-5 md:col-start-8 -mb-32">
              <div className="py-32" id="comp-container">
                <FadeInWhenVisible transition={{ delay: '.25' }} className="max-w-4xl">
                  <MultiPropertyOwnerForm className="text-white" language={section.language ?? 'en'} />
                </FadeInWhenVisible>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  )
}
